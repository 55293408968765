import { ICartSelection, IField, IFieldOption } from '@/client/types'
import { Selection } from './types'

export const getOptionValue = (options: IFieldOption[], selection: Selection, key: string): string => {
  if (key === 'diameter') {
    return `${selection.diameter}mm`
  }

  if (key === 'dimension') {
    return `${selection.width}x${selection.height}mm`
  }

  if (key === 'variation') {
    return `${selection.sorts}`
  }

  if (key === 'quantity') {
    return `${selection.quantity}`
  }

  if (key === 'assembly') {
    if (selection?.labels_per_roll) {
      const optionName = options.find((option) => option.key === 'labels_per_roll')?.name
      return `${selection.labels_per_roll} ${optionName}`
    }

    if (selection?.max_roll_diameter) {
      const optionName = options.find((option) => option.key === 'max_roll_diameter')?.name
      return `${optionName}: ${selection.max_roll_diameter} mm`
    }

    return '-'
  }

  if (key === 'extras') {
    const extraList: string[] = []

    options.forEach((option) => {
      if ((selection?.extras as number[])?.includes(Number(option.id))) {
        extraList.push(option.name)
      }
    })
    return extraList.length > 0 ? extraList.join(', ') : '-'
  }

  if (key === 'double_hit') {
    const doubleHitList: string[] = []

    options.forEach((option) => {
      if ((selection?.double_hit as number[])?.includes(Number(option.id))) {
        doubleHitList.push(option.name)
      }
    })
    return doubleHitList.length > 0 ? doubleHitList.join(', ') : '-'
  }

  if (key === 'no_artwork_service') {
    return ''
  }

  const item = options.find((option) => option.id === selection[key]) as IFieldOption
  return item?.name
}

export const getOptionValueForShortSummary = (selection: ICartSelection, key: string): string => {
  if (key === 'diameter') {
    return `${selection?.diameter}mm`
  }

  if (key === 'dimension') {
    return `${selection?.width}x${selection?.height}mm`
  }

  if (key === 'quantity') {
    return `${selection?.quantity}`
  }
  return ''
}

export const getOptionsField = (fields: IField[] | undefined, key: string): IFieldOption[] => {
  const item = fields?.find((field) => field.key === key)
  return item?.options ?? []
}

export const updateInitialSelection = (selection) => {
  const newSelection = { ...selection }
  newSelection.extras = newSelection.extras ? newSelection.extras : []
  newSelection.express_production = newSelection.express_production ? newSelection.express_production : false
  newSelection.order_comment = newSelection.order_comment || newSelection['Order Comment']
  newSelection.order_name = newSelection.order_name || newSelection['Order name']
  newSelection.labels_per_roll = newSelection.labels_per_roll || newSelection['labels per roll']
  newSelection.max_roll_diameter = newSelection.max_roll_diameter || newSelection['max. roll diameter']
  newSelection.double_hit = newSelection.double_hit ? newSelection.double_hit : []
  return newSelection
}

export const isExcludingField = (excludes, selection: ICartSelection): boolean => {
  if (!excludes) return true
  const isExcluded = excludes.some(({ id, value }) => selection?.[id] === value)
  return !isExcluded
}

export const getCorrectDimensionType = (selection: ICartSelection, calculatorFields): string => {
  const excludeOfDiameter = isExcludingField(
    calculatorFields?.find((field) => field.key === 'diameter')?.exclude,
    selection
  )
  const excludeOfDimension = isExcludingField(
    calculatorFields?.find((field) => field.key === 'dimension')?.exclude,
    selection
  )
  if (excludeOfDiameter) return 'diameter'
  if (excludeOfDimension) return 'dimension'
  return ''
}
